import React, {useState, useEffect } from "react";
import Highcharts from "highcharts/highmaps";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsData from "highcharts/modules/data";

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsData(Highcharts);

const Map = ({data}) => {
  
  const [northRegionProjects,SetNorthRegionProjects]=useState('');
const [southRegionProjects,setSouthRegionProjects]=useState('');
const [eastRegionProjects,setEastRegionProjects]=useState('');
const [nigeriaRegionProjects,setNigeriaRegionProjects]=useState('');
//const [multiRegionProjects,setMultiRegionProjects]=useState('');
const [westRegionProjects,setWestRegionProjects]=useState('');
const [centralRegionProjects,setCentralRegionProjects]=useState('');


  useEffect(() => {
    if (data) {
      const northRegionData = data.filter((item) => item.africanRegion === "North").map((item) => item.totalNumberOfProjects)[0];
      SetNorthRegionProjects(northRegionData || 0); // Set to 0 if undefined
      const southRegionData = data.filter((item) => item.africanRegion === "South").map((item) => item.totalNumberOfProjects)[0];
      setSouthRegionProjects(southRegionData || 0); // Set to 0 if undefined
      const eastRegionData = data.filter((item) => item.africanRegion === "East").map((item) => item.totalNumberOfProjects)[0];
      setEastRegionProjects(eastRegionData || 0); // Set to 0 if` undefined
      const nigeriaRegionData = data.filter((item) => item.africanRegion === "Nigeria").map((item) => item.totalNumberOfProjects)[0];
      setNigeriaRegionProjects(nigeriaRegionData || 0); // Set to 0 if undefined
     // const multiRegionData = data.filter((item) => item.africanRegion === "Multi").map((item) => item.totalNumberOfProjects)[0];
     // setMultiRegionProjects(multiRegionData || 0); // Set to 0 if undefined
      const westRegionData = data.filter((item) => item.africanRegion === "West").map((item) => item.totalNumberOfProjects)[0];
      setWestRegionProjects(westRegionData || 0); // Set to 0 if undefined
      const centralRegionData = data.filter((item) => item.africanRegion === "Central").map((item) => item.totalNumberOfProjects)[0];
      setCentralRegionProjects(centralRegionData || 0); // Set to 0 if undefined
    }
  }, [data]);

  useEffect(() => {
    (async () => {
      const topologyResponse = await fetch(
        "https://code.highcharts.com/mapdata/custom/africa.topo.json"
      );
      const topology = await topologyResponse.json();
      const data = [
        ["ng", 1],
        ["tn", 2],
        ["dz", 3],
        ["ly", 4],
        ["eh", 5],
        ["mr", 6],
        ["ma", 7],
        ["eg", 8],
        ["ne", 9],
        ["ml", 10],
        ["sn", 11],
        ["gn", 12],
        ["gw", 13],
        ["bf", 14],
        ["gh", 15],
        ["sl", 16],
        ["tg", 17],
        ["bj", 18],
        ["ci", 19],
        ["lr", 20],
        ["za", 21],
        ["na", 22],
        ["bw", 23],
        ["ls", 24],
        ["sz", 25],
        ["mz", 26],
        ["zw", 27],
        ["zm", 28],
        ["ao", 29],
        ["mw", 30],
        ["mg", 31],
        ["td", 32],
        ["cm", 33],
        ["cf", 34],
        ["gq", 35],
        ["ga", 36],
        ["cg", 37],
        ["cd", 38],
        ["sd", 39],
        ["er", 40],
        ["dj", 41],
        ["et", 42],
        ["ss", 43],
        ["ug", 44],
        ["bi", 45],
        ["tz", 46],
        ["ke", 47],
        ["so", 48],
        ["sx", 49],
      ];
      
      
     

      Highcharts.mapChart("map-container", {
        chart: {
          map: topology,
          height:600,
        },
        title: {
          text: "",
        },
        tooltip: {
          enabled: false,
          formatter: function () {
            return '<b>' +  this.point.name;
          },
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: "bottom",
          },
        },
        plotOptions: {
          mapbubble: {
            keys: ["id", "lat", "lon", "name","z"],
           
            dataLabels: {
              enabled: true,
              formatter: function () {
                return '<b>' +  this.point.name ;
              },
              color:'black',
              style: {
                textOutline: 'none',
              },
             
            },
          },
        },
        series: [
          {
            name: "",
            borderColor: "#A0A0A0",
            showInLegend: false,
          },
          {
            name: "",
            type: "mapbubble",
            data: [
              { 
                id: "dz",
               // data:data,
                z: northRegionProjects,
                name: `${northRegionProjects} North`,
             //  value:`${northRegionProjects} North`,
                lat: 28.05,
                lon: 10.24,
              //  y:3
              },
              {
                id: "za",
                z: southRegionProjects,
                name: `${southRegionProjects} South`,
              //  value: `${southRegionProjects} South`,
                lat: -20.74,
                lon: 25.19,
              //  y:21
              },
              {
                id: "et",
                z: eastRegionProjects,
                name: `${eastRegionProjects} East`,
              //  value: `${eastRegionProjects} East`,
                lat: 9.15,
                lon: 36.74,
              //  y:42
              },
              {
                id: "cd",
                z: centralRegionProjects,
                name: `${centralRegionProjects} Center`,
           
                lat: 2.56,
                lon: 20.2,
          
              },
              {
                id: "ng",
                z: nigeriaRegionProjects,
                name: `${nigeriaRegionProjects} Nigeria`,
           
                lat: 9,
                lon: 8.01,
           
              },
              {
                id: "bf",
                z: westRegionProjects,
                name: `${westRegionProjects} West`,
               
                lat: 13.31,
                lon: -4.59,
             
              },
            ],
            
            nullColor: "#707070",
            showInLegend: false,
            enableMouseTracking: true, 
            accessibility: {
              enabled: false,
            },
          },
        ],
        exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              symbol: "menuball",
              symbolSize: "10",
              symbolFill: "#666666",
              text: "",
              symbolStrokeWidth: "1",
              menuItems: [
                "viewFullscreen",
                "printChart",
                "downloadPNG",
                "downloadJPEG",
                "downloadPDF",
                "downloadSVG",
                "downloadCSV",
                "downloadXLS",
                "viewData",
              ],
              x: 16,
              y: -16,
            },
          },
        },
        legend: {
          enabled: false,
        },
      });
      
    })();
  }, [northRegionProjects,southRegionProjects,eastRegionProjects,centralRegionProjects,nigeriaRegionProjects,westRegionProjects]);

  return (
    <div>
      <div id="map-container"></div>
    </div>
  );
};

export default Map;
