import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsDumbbell from "highcharts/modules/dumbbell";
import HC_more from "highcharts/highcharts-more";

HC_more(Highcharts);
highchartsDumbbell(Highcharts);

const SectoralInvestment = ({data}) => {
  let inputData=[];
  if(data){
    inputData= data.map((item)=>{
      return {
    name:item.sectorAnalysis,
    low:item.minUAAmount,
    high:item.maxUAAmount
      }
    })
  }


  const chartOptions = {
    chart: {
      type: "dumbbell",
      height:400,
      inverted: true,
    },

    legend: {
      enabled: false
    },

    subtitle: {
      text: ""
    },

    title: {
      text: ""
    },

    tooltip: {
      shared: true
    },

    xAxis: {
      type: "category",
      opposite: true,
    },

    yAxis: {
        title: {
          text: ""
        },
      
       // tickPositions: [0, 250, 500, 750, 1000, 1250, 1500, 1750, 2000],
         // Set this property to true to display tick positions on the opposite side
      },

      plotOptions: {
        series: {
            connectorWidth: 3,
            marker: {
                radius: 5,
                states: {
                    hover: {
                        lineWidth: 0
                    }
                }
            },
            dataLabels: {
                enabled: true,
                color: 'black',
                crop: false,
                overflow: 'allow',
                align:'center',
                style: {
                  textOutline: 'none',
                  color:'black'
                },  
            }
        }
    },
      
    exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            symbol:'menuball',
            symbolSize:'10',
            symbolFill:'#666666',
            text:'',
            symbolStrokeWidth:'1',
            menuItems: [
              'viewFullscreen',
              'printChart',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'downloadCSV',
              'downloadXLS',
              'viewData',
            ],
           x:16,
           y:-16,
          },
        },
      },
      series: [{
        name: 'Ua',
        data: inputData,
        color: Highcharts.getOptions().colors[2],
        marker: {
            enabled: true,
            symbol: 'diamond',
        },
        lowMarker: {
            enabled: false
        }
    },
 ]
  };
 
  

  return (
    <div className="card bg-white rounded-md card-shadow grid-margin flex1">
    <div className="cardBody">
       <div class="cardTitle flex items-center flex-wrap items-center justify-between">
         <div class="mb-4">
               <p class="f14 semibold text-uppercase text-normal">SECTORAL INVESTMENT RANGES</p>
               <p class="f13 semibold text-light text-benGray">Span Of Minimum And Maximum Financial Contributions By Sector</p>
         </div>   
       </div>
       <div class="mt-5">
       <HighchartsReact highcharts={Highcharts} options={chartOptions} />
   </div>
 </div>
 </div>
  );
};

export default SectoralInvestment;