import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
 
const Optimizing = () => {
 
    const options = {
      chart: {
        backgroundColor:'',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: '',
        align: 'left'
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.1f}%',
            distance: -30, // Adjust this distance to move the labels towards the center
            style: {
              fontWeight: 'bold',
              color: 'black',
              textOutline: 'none',
            },
           
            formatter: function () {
              return '<b>' + this.point.name + ':</b> ' + this.percentage.toFixed(1) + '%';
            }
          },
          center: ['50%', '50%'], // Set the center of the pie chart
          borderRadius: 0,
          showInLegend: true
        }
      },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          symbol:'menuball',
          symbolSize:'10',
          symbolFill:'#666666',
          text:'',
          symbolStrokeWidth:'1',
          menuItems: [
            'viewFullscreen',
            'printChart',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'downloadCSV',
            'downloadXLS',
            'viewData',
          ],
         x:16,
         y:-16,
        },
      },
    },
 
 
    series: [{
        name: 'Optimizing Selectivity',
        colorByPoint: true,
        data: [{
            name: '<1-50Mn',
            y: 70,
            sliced: false,
            selected: true,
            color:"#32de84"
 
        },  {
            name: '50-100Mn',
            y: 12.82,
            color:"#00FFFF"
        },  {
            name: '100-200Mn',
            y: 4.63 ,
            color:"#FF00FF"
        }, {
            name: '100-300Mn',
            y: 2.44
        }, {
            name: '300Mn',
            y: 2.02
        },]
    }]
 
    };
   
  return (
 
         
          <div className='mt-5'>
             <HighchartsReact highcharts={Highcharts} options={options} />
         </div>
 
  );
};
 
export default Optimizing;