export const userRoles = [
  'Administrator',
  'Senior VP',
  'VP RDVP',
  'Sector VP',
  'Director General',
  'Sector Director',
  'Country Manager',
  'Sector Manager',
  'Regional Implementation Manager',
  'RDVP CPC',
  'RDVP Staff',
  'Lead Expert',
  'Regional Program Officer',
  'Country Program Officer',
  'Task Manager',
  'Analyst',
  'Admin & Finance Officer',
  'Budget Focal Point',
  'Complex Coordinator',
  'Management',
  'Sector Expert',
  'Ecosystem',
  'Operations Support',
  'Implementation Agencies',
];

export const regionalData = [
  {
    id: 1,
    title: 'Bank Wide',
    color: 'text-orange-500',
    avg: '42',
    below: '50',
  },
  {
    id: 2,
    title: 'East',
    color: 'text-green-500',
    avg: '78',
    below: '29',
  },
  {
    id: 3,
    title: 'South',
    color: 'text-orange-500',
    percentage: '24',
    avg: '70',
    below: '40',
  },
  {
    id: 4,
    title: 'North',
    color: 'text-red-500',
    avg: '100',
    below: '58.2',
  },
  {
    id: 5,
    title: 'West',
    color: 'text-orange-500',
    avg: '77.8',
    below: '25',
  },
  {
    id: 6,
    title: 'Nigeria',
    color: 'text-red-500',
    avg: '22',
    below: '29',
  },
  {
    id: 7,
    title: 'Regional',
    color: 'text-green-500',
    avg: '40',
    below: '40',
  },
  {
    id: 8,
    title: 'Central',
    color: 'text-green-500',
    avg: '40',
    below: '40',
  },
];

export const complexData = [
  {
    id: 1,
    title: 'AHVP',
    color: 'text-orange-500',
    avg: '42',
    below: '50',
  },
  {
    id: 2,
    title: 'PEVP',
    color: 'text-green-500',
    avg: '78',
    below: '29',
  },
  {
    id: 3,
    title: 'PIVP',
    color: 'text-orange-500',
    avg: '70',
    below: '40',
  },
  {
    id: 4,
    title: 'ECVP',
    color: 'text-red-500',
    avg: '100',
    below: '58.2',
  },
];
