import React from 'react';


const TaskManagerAnalysis = () => {

   
  return (
    <div className='taskManager_workload flex flex1 justify-center flex1 border-t'>
       <div className='flex items-center flex-col px-4 py-4 h100 justify-center flex1 taskManager_card'>
          <div className='mb-5'>
            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
              <path d="M13.8577 9.19205C16.2264 9.19205 18.1466 7.27187 18.1466 4.9032C18.1466 2.53453 16.2264 0.614349 13.8577 0.614349C11.489 0.614349 9.56885 2.53453 9.56885 4.9032C9.56885 7.27187 11.489 9.19205 13.8577 9.19205Z" fill="#7C86FF"/>
              <path d="M23.8599 29.7257C23.739 28.7792 23.0536 28.0021 22.1298 27.7635L17.1478 26.4773L17.259 24.9095C16.4092 24.7475 15.6932 24.1676 15.3558 23.3777L12.3187 16.235L16.9477 22.1269C17.3487 22.6372 17.9787 22.915 18.6298 22.8635L22.6341 22.5449C22.6066 22.6399 22.5911 22.7399 22.5911 22.8438C22.5911 23.436 23.0711 23.9161 23.6633 23.9161C24.6611 23.9161 32.1231 23.9161 33.3362 23.9161C33.8211 23.9161 34.2457 23.5906 34.3717 23.1223L37.0942 12.9999C37.248 12.428 36.9091 11.8397 36.3372 11.6859C35.7653 11.5323 35.1771 11.8709 35.0233 12.4429L32.5142 21.7715C31.4847 21.7715 28.498 21.7715 27.4928 21.7715C27.9387 21.3806 28.2022 20.7931 28.1516 20.1567C28.0665 19.0869 27.1317 18.2878 26.0605 18.3737L19.3602 18.9068L15.3748 13.8341L17.9109 15.7262L17.9924 14.5785C18.1057 12.9825 16.9037 11.5967 15.3075 11.4834L10.39 11.1344C8.7063 11.0149 7.27486 12.3567 7.28768 14.0464L7.39673 28.389C7.40122 28.9836 7.61988 29.5511 8.00383 29.9929H6.01953L3.93029 13.4957C3.82249 12.644 3.04493 12.0406 2.19286 12.1489C1.34118 12.2567 0.738091 13.0346 0.845986 13.8863L3.1073 31.7426C3.20573 32.5194 3.86649 33.1018 4.6495 33.1018H10.2632V39.4678L6.57058 42.4822C6.11183 42.8566 6.04344 43.5321 6.41801 43.9909C6.79249 44.4497 7.46808 44.5179 7.92673 44.1434L10.2631 42.2361V43.3128C10.2631 43.905 10.7432 44.3851 11.3354 44.3851C11.9276 44.3851 12.4077 43.905 12.4077 43.3128V42.2361L14.7441 44.1434C14.9432 44.306 15.1831 44.3852 15.4216 44.3852C15.7324 44.3851 16.0409 44.2506 16.2529 43.9909C16.6274 43.5321 16.5591 42.8566 16.1003 42.4822L12.4077 39.4678V33.1018H17.5266C18.3032 33.1018 18.945 32.5317 19.0606 31.7875L19.4342 31.8839L20.7713 42.3486C20.9346 43.627 22.1034 44.5293 23.3797 44.366C24.6572 44.2029 25.5603 43.0349 25.3971 41.7576L23.8599 29.7257Z" fill="#7C86FF"/>
              <path d="M43.2786 25.3087H25.6214C24.763 25.3087 24.0669 26.0046 24.0669 26.8632C24.0669 27.7218 24.7629 28.4178 25.6214 28.4178H41.7241V41.2766H34.4081C33.5497 41.2766 32.8536 41.9725 32.8536 42.8311C32.8536 43.6897 33.5496 44.3856 34.4081 44.3856H43.2786C44.1371 44.3856 44.8332 43.6897 44.8332 42.8311V26.8631C44.8332 26.0047 44.1372 25.3087 43.2786 25.3087Z" fill="#7C86FF"/>
            </svg>
          </div>
           <div className="text-center">
              <p className="f26 semibold text-bold mb-2 textHoverPurple">327</p>   
              <p className='semibold f13 text-benGray'>TMs Available to Work</p>     
            </div>
        </div>
        <div className='flex items-center flex-col px-4 py-4 h100 justify-center border-l  flex1 taskManager_card'>
           <div className='mb-5'>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="39" viewBox="0 0 40 39" fill="none">
                <path d="M2.29175 5.75H3.66675V7.125C3.66675 7.88501 4.28241 8.5 5.04175 8.5C5.80109 8.5 6.41675 7.88501 6.41675 7.125V5.75H7.79175C8.55109 5.75 9.16675 5.13501 9.16675 4.375C9.16675 3.61499 8.55109 3 7.79175 3H6.41675V1.625C6.41675 0.86499 5.80109 0.25 5.04175 0.25C4.28241 0.25 3.66675 0.86499 3.66675 1.625V3H2.29175C1.53241 3 0.916748 3.61499 0.916748 4.375C0.916748 5.13501 1.53241 5.75 2.29175 5.75Z" fill="#7C86FF"/>
                <path d="M32.5417 5.75H33.9167V7.125C33.9167 7.88501 34.5324 8.5 35.2917 8.5C36.0511 8.5 36.6667 7.88501 36.6667 7.125V5.75H38.0417C38.8011 5.75 39.4167 5.13501 39.4167 4.375C39.4167 3.61499 38.8011 3 38.0417 3H36.6667V1.625C36.6667 0.86499 36.0511 0.25 35.2917 0.25C34.5324 0.25 33.9167 0.86499 33.9167 1.625V3H32.5417C31.7824 3 31.1667 3.61499 31.1667 4.375C31.1667 5.13501 31.7824 5.75 32.5417 5.75Z" fill="#7C86FF"/>
                <path d="M7.79175 33.25H6.41675V31.875C6.41675 31.115 5.80109 30.5 5.04175 30.5C4.28241 30.5 3.66675 31.115 3.66675 31.875V33.25H2.29175C1.53241 33.25 0.916748 33.865 0.916748 34.625C0.916748 35.385 1.53241 36 2.29175 36H3.66675V37.375C3.66675 38.135 4.28241 38.75 5.04175 38.75C5.80109 38.75 6.41675 38.135 6.41675 37.375V36H7.79175C8.55109 36 9.16675 35.385 9.16675 34.625C9.16675 33.865 8.55109 33.25 7.79175 33.25Z" fill="#7C86FF"/>
                <path d="M38.0417 33.25H36.6667V31.875C36.6667 31.115 36.0511 30.5 35.2917 30.5C34.5324 30.5 33.9167 31.115 33.9167 31.875V33.25H32.5417C31.7824 33.25 31.1667 33.865 31.1667 34.625C31.1667 35.385 31.7824 36 32.5417 36H33.9167V37.375C33.9167 38.135 34.5324 38.75 35.2917 38.75C36.0511 38.75 36.6667 38.135 36.6667 37.375V36H38.0417C38.8011 36 39.4167 35.385 39.4167 34.625C39.4167 33.865 38.8011 33.25 38.0417 33.25Z" fill="#7C86FF"/>
                <path d="M26.2817 5.89502L20.7817 3.14502C20.395 2.95166 19.9384 2.95166 19.5517 3.14502L14.0517 5.89502C14.0039 5.91894 13.9664 5.95536 13.9224 5.98415L20.1667 9.10626L26.411 5.98415C26.3669 5.95536 26.3294 5.91894 26.2817 5.89502Z" fill="#7C86FF"/>
                <path d="M13.2917 14C13.2917 14.521 13.5858 14.997 14.0518 15.23L19.4792 18.125V10.3L13.2917 7.20624V14Z" fill="#7C86FF"/>
                <path d="M27.0417 14V7.20624L20.8542 10.3V18.125L26.2817 15.23C26.7477 14.997 27.0417 14.521 27.0417 14Z" fill="#7C86FF"/>
                <path d="M18.0317 19.645L12.5317 16.895C12.145 16.7017 11.6884 16.7017 11.3017 16.895L5.80169 19.645C5.75394 19.6689 5.71642 19.7054 5.67236 19.7341L11.9167 22.8563L18.161 19.7341C18.1169 19.7054 18.0794 19.6689 18.0317 19.645Z" fill="#7C86FF"/>
                <path d="M5.04175 27.75C5.04175 28.271 5.33582 28.747 5.80176 28.98L11.2292 31.875V24.05L5.04175 20.9562V27.75Z" fill="#7C86FF"/>
                <path d="M12.6042 31.875L18.0317 28.98C18.4977 28.747 18.7917 28.271 18.7917 27.75V20.9562L12.6042 24.05V31.875Z" fill="#7C86FF"/>
                <path d="M34.5317 19.645L29.0317 16.895C28.645 16.7017 28.1884 16.7017 27.8017 16.895L22.3017 19.645C22.2539 19.6689 22.2164 19.7054 22.1724 19.7341L28.4167 22.8563L34.661 19.7341C34.6169 19.7054 34.5794 19.6689 34.5317 19.645Z" fill="#7C86FF"/>
                <path d="M21.5417 27.75C21.5417 28.271 21.8358 28.747 22.3018 28.98L27.7292 31.875V24.05L21.5417 20.9562V27.75Z" fill="#7C86FF"/>
                <path d="M35.2917 27.75V20.9562L29.1042 24.05V31.875L34.5317 28.98C34.9977 28.747 35.2917 28.271 35.2917 27.75Z" fill="#7C86FF"/>
              </svg>
            </div>
           <div className="text-center">
              <p className="f26 semibold text-bold mb-2 textHoverPurple">-24</p>   
              <p className='semibold f13 text-benGray'>TMs Available to Work</p>     
            </div>
        </div>
    </div>
  );
};

export default TaskManagerAnalysis;
