import React, { useEffect } from 'react';
import Highcharts from 'highcharts/highmaps';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsData from 'highcharts/modules/data';
 
 
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsData(Highcharts);

const HoursByMonthMap = () => {
  useEffect(() => {
    (async () => {
      const topologyResponse = await fetch(
        'https://code.highcharts.com/mapdata/custom/africa.topo.json'
      );
      const topology = await topologyResponse.json();

    const data = [
        
        ['ng', 0],['tn', 1],['dz', 2], ['ly', 3],['eh', 4],['mr', 5], ['ma', 6],['eg', 7],
       ['ne', 9], ['ml', 10],['sn', 11],['gn', 12],['gw', 13],['bf', 14],['gh', 15],['sl', 16],
       ['tg', 17],['bj', 18],['ci', 19],['lr', 20],['za', 21],['na', 22],['bw', 23],['ls', 24],['sz', 25],['mz', 26],
      ['zw', 27],['zm', 28],['ao', 29],['mw', 30],['mg', 31],['td', 32],['cm', 33],['cf', 34],['gq', 35],['ga', 36],['cg', 37],
      ['cd', 38], ['sd', 39], ['er', 40],['dj', 41], ['et', 42], ['ss', 43], ['ug', 44], ['bi', 45], ['tz', 46], ['ke', 47], ['so', 48], ['sx', 49]
   ];

      Highcharts.mapChart('map-container', {
        chart: {
          map: topology,
          backgroundColor:'',
        },
        title: {
          text: '',
        },
       
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: 'bottom'
        }
        },
        colorAxis: {
          dataClasses: [
            {
              name:'Nigeria',
              to: 0,
              color: "#04F5D1",
            },
            {
              name:'North',
              from: 1,
              to: 7,
              color: "#A292F8",
            },
            {
              name:'West',
              from: 9,
              to: 20,
              color: "#81808F",
            },
            {
              name:'South',
              from: 21,
              to: 31,
              color: "#71C6F3",
            },
            {
              name:'Center',
              from: 32,
              to: 38,
              color: "#32CDA4",
            },
            {
              name:'East',
              from: 39,
              to: 49,
              color: "#5895F3",
            },
          ],
        },
        plotOptions: {
          mappoint: {
              keys: ['id', 'lat', 'lon', 'name', 'y'],
              marker: {
                  lineWidth: 2,
                  lineColor: 'grey',
                  symbol: 'marker',
                  radius: 18,
                  fillColor: 'none' // Set the fill color to 'none'
              },
              dataLabels: {
                  enabled: true,
                  style: {
                    textOutline: 'none',
                  },
                  y: 10,
                  color: 'black'
              }
          }
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            symbol:'menuball',
            symbolSize:'10',
            symbolFill:'#666666',
            text:'',
            symbolStrokeWidth:'1',
            menuItems: [
              'viewFullscreen',
              'printChart',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'downloadCSV',
              'downloadXLS',
              'viewData',
            ],
           x:16,
           y:-16,
          },
        },
      },
        series: [{
            data: data,
            // name: 'Random data',
            // states: {
            //     hover: {
            //         color: '#BADA55'
            //     }
            // },
           
        },
        {
            name: 'Landlocked',
            color: 'rgb(241, 92, 128)',
            data: [ 
      ['eg', 27.05, 10.24, '16 Staff', 2],
     ['za', -20.74, 25.19, '48 Staff', 2],
      ['et', 9.15, 38.74, '33 Staff', 3],
      ['gh', -2.56, 22.20, '10 Staff', 0],
     ['ci', 9, 8.01, '6 Staff', 0],
     ['ug', 18.31, -1.59, '19 Staff', 3],
       //  ['ng', 9.08, 7.51, 'Lagos', 1],
    // ['ke', -1.28, 36.82, 'Nairobi', 3],
    //  ['ma', 33.59, -7.62, 'Casablanca', 0],
       // ['cm', 3.87, 11.52, 'Yaoundé', 1],
    ],
            type: 'mappoint'
        }]
      });
    })();
  }, []);

  return (
    <div >
      <div id='map-container' style={{ height: '440px', width: '100%' }}></div>
    </div>
  );
};

export default HoursByMonthMap;
