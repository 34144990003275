import React from 'react';


const StaffWeekAnalysis = () => {

   
  return (
    <div className='card bg-white rounded-md grid-margin card-shadow KPI-peformanceGrid staff_week_content'>
      <div className='cardBody flex flex-col h100 '>
        <div class="cardTitle flex justify-between items-center">
            <div className='mb-4 items-self-center'> 
                <p class="f14 semibold text-uppercase text-normal">Staff Week ANalysis</p>
            </div>
        </div>
        <div class="flex flex1 justify-center flex-col gap-y-5 staff_week_grid">
            <div class="flex items-center px-5 py-5 h100 BgYellow border_Yellow border-t border-b border-l border-r rounded-md gap-x-5 staff_week_item overlay_card">
               <div className="BgLtblue border_Ltblue icon_bg iconXlg_bg border-t border-l border-r border-b">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                     <path d="M0.583252 0.5V22.7857H14.4294V21.0714H2.31402V2.21429H10.9679V7.35714H16.1602V9.07143H17.8909V6.15681L17.6307 5.90067L12.4383 0.757812L12.1797 0.5H0.583252ZM12.6986 3.41462L14.9483 5.64286H12.6986V3.41462ZM4.04479 9.07143V10.7857H14.4294V9.07143H4.04479ZM18.7563 10.7857V12.5C17.2852 12.7571 16.1602 13.9571 16.1602 15.5C16.1602 17.2143 17.4583 18.5 19.189 18.5H20.0544C20.7467 18.5 21.3525 19.1 21.3525 19.7857C21.3525 20.4714 20.7467 21.0714 20.0544 21.0714H17.0256V22.7857H18.7563V24.5H20.4871V22.7857C21.9583 22.5286 23.0833 21.3286 23.0833 19.7857C23.0833 18.0714 21.7852 16.7857 20.0544 16.7857H19.189C18.4967 16.7857 17.8909 16.1857 17.8909 15.5C17.8909 14.8143 18.4967 14.2143 19.189 14.2143H22.2179V12.5H20.4871V10.7857H18.7563ZM4.04479 13.3571V15.0714H10.1025V13.3571H4.04479ZM11.8333 13.3571V15.0714H14.4294V13.3571H11.8333ZM4.04479 16.7857V18.5H10.1025V16.7857H4.04479ZM11.8333 16.7857V18.5H14.4294V16.7857H11.8333Z" fill="#44BADC"/>
                  </svg>
                </div>
              <div class="">
                <p class="f24 semibold text-benLightBlue text-bold mb-2">12426</p>
                <p class="semibold f13 text-benGray">Available SWs Based On Current TMs</p>
              </div>
            </div>
            <div class="flex items-center px-5 py-5 h100 rounded-md BgPurple border_purple border-t border-b border-l border-r gap-x-5 staff_week_item overlay_card">
               <div className="BgLtblue border_Ltblue icon_bg iconXlg_bg border-t border-l border-r border-b">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="39" viewBox="0 0 40 39" fill="none">
                      <path d="M1.95825 5.75H3.33325V7.125C3.33325 7.88501 3.94891 8.5 4.70825 8.5C5.46759 8.5 6.08325 7.88501 6.08325 7.125V5.75H7.45825C8.21759 5.75 8.83325 5.13501 8.83325 4.375C8.83325 3.61499 8.21759 3 7.45825 3H6.08325V1.625C6.08325 0.86499 5.46759 0.25 4.70825 0.25C3.94891 0.25 3.33325 0.86499 3.33325 1.625V3H1.95825C1.19891 3 0.583252 3.61499 0.583252 4.375C0.583252 5.13501 1.19891 5.75 1.95825 5.75Z" fill="#7C86FF"/>
                      <path d="M32.2083 5.75H33.5833V7.125C33.5833 7.88501 34.1989 8.5 34.9583 8.5C35.7176 8.5 36.3333 7.88501 36.3333 7.125V5.75H37.7083C38.4676 5.75 39.0833 5.13501 39.0833 4.375C39.0833 3.61499 38.4676 3 37.7083 3H36.3333V1.625C36.3333 0.86499 35.7176 0.25 34.9583 0.25C34.1989 0.25 33.5833 0.86499 33.5833 1.625V3H32.2083C31.4489 3 30.8333 3.61499 30.8333 4.375C30.8333 5.13501 31.4489 5.75 32.2083 5.75Z" fill="#7C86FF"/>
                      <path d="M7.45825 33.25H6.08325V31.875C6.08325 31.115 5.46759 30.5 4.70825 30.5C3.94891 30.5 3.33325 31.115 3.33325 31.875V33.25H1.95825C1.19891 33.25 0.583252 33.865 0.583252 34.625C0.583252 35.385 1.19891 36 1.95825 36H3.33325V37.375C3.33325 38.135 3.94891 38.75 4.70825 38.75C5.46759 38.75 6.08325 38.135 6.08325 37.375V36H7.45825C8.21759 36 8.83325 35.385 8.83325 34.625C8.83325 33.865 8.21759 33.25 7.45825 33.25Z" fill="#7C86FF"/>
                      <path d="M37.7083 33.25H36.3333V31.875C36.3333 31.115 35.7176 30.5 34.9583 30.5C34.1989 30.5 33.5833 31.115 33.5833 31.875V33.25H32.2083C31.4489 33.25 30.8333 33.865 30.8333 34.625C30.8333 35.385 31.4489 36 32.2083 36H33.5833V37.375C33.5833 38.135 34.1989 38.75 34.9583 38.75C35.7176 38.75 36.3333 38.135 36.3333 37.375V36H37.7083C38.4676 36 39.0833 35.385 39.0833 34.625C39.0833 33.865 38.4676 33.25 37.7083 33.25Z" fill="#7C86FF"/>
                      <path d="M25.9482 5.89502L20.4482 3.14502C20.0615 2.95166 19.6049 2.95166 19.2182 3.14502L13.7182 5.89502C13.6704 5.91894 13.6329 5.95536 13.5889 5.98415L19.8332 9.10626L26.0775 5.98415C26.0334 5.95536 25.9959 5.91894 25.9482 5.89502Z" fill="#7C86FF"/>
                      <path d="M12.9583 14C12.9583 14.521 13.2523 14.997 13.7183 15.23L19.1458 18.125V10.3L12.9583 7.20624V14Z" fill="#7C86FF"/>
                      <path d="M26.7083 14V7.20624L20.5208 10.3V18.125L25.9482 15.23C26.4142 14.997 26.7083 14.521 26.7083 14Z" fill="#7C86FF"/>
                      <path d="M17.6982 19.645L12.1982 16.895C11.8115 16.7017 11.3549 16.7017 10.9682 16.895L5.46819 19.645C5.42044 19.6689 5.38293 19.7054 5.33887 19.7341L11.5832 22.8563L17.8275 19.7341C17.7834 19.7054 17.7459 19.6689 17.6982 19.645Z" fill="#7C86FF"/>
                      <path d="M4.70825 27.75C4.70825 28.271 5.00232 28.747 5.46826 28.98L10.8958 31.875V24.05L4.70825 20.9562V27.75Z" fill="#7C86FF"/>
                      <path d="M12.2708 31.875L17.6982 28.98C18.1642 28.747 18.4583 28.271 18.4583 27.75V20.9562L12.2708 24.05V31.875Z" fill="#7C86FF"/>
                      <path d="M34.1982 19.645L28.6982 16.895C28.3115 16.7017 27.8549 16.7017 27.4682 16.895L21.9682 19.645C21.9204 19.6689 21.8829 19.7054 21.8389 19.7341L28.0832 22.8563L34.3275 19.7341C34.2834 19.7054 34.2459 19.6689 34.1982 19.645Z" fill="#7C86FF"/>
                      <path d="M21.2083 27.75C21.2083 28.271 21.5023 28.747 21.9683 28.98L27.3958 31.875V24.05L21.2083 20.9562V27.75Z" fill="#7C86FF"/>
                      <path d="M34.9583 27.75V20.9562L28.7708 24.05V31.875L34.1982 28.98C34.6642 28.747 34.9583 28.271 34.9583 27.75Z" fill="#7C86FF"/>
                  </svg>
               </div>
                <div class="">
                  <p class="f24 semibold text-bold">-192</p>
                  <p class="semibold f13 text-benGray">Gap In SWs Used For Computations</p>
                </div>
            </div>
        </div>
      </div>
      {/* <div className='cardTitle flex items-center p-3 border-b border-benThinBlue'>
        <p className='text-benBlue text-base lg:text-lg semibold'>

        </p>
      </div> */}  
   </div>
   
  );
};

export default StaffWeekAnalysis;
